body {
  font-family: Roboto, sans-serif;
}

header > h1 {
  text-align: center;
  color: #5b9618;
  font-family: Niconne, cursive;
  font-size: 4rem;
  font-weight: normal;
}

.form-container {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.form-container input[type="number"] {
  width: 100px;
  background-color: #f1f1f1;
  border-style: none none solid;
  border-width: medium medium 2px;
  border-top-color: currentColor;
  border-left-color: currentColor;
  border-right-color: currentColor;
  border-radius: 3px;
  padding: 6px;
  font-size: 1rem;
}

.form-container input:focus-visible {
  border-bottom-color: #5b9618;
  outline: 0;
}

.form-container button {
  color: #fff;
  cursor: pointer;
  background-color: #5b9618;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 1rem;
}

.form-container button:hover {
  background-color: #73ac33;
}

.form-container button:active {
  transform: translate(1px, 1px);
}

.form-container button:disabled {
  cursor: default;
  background-color: #ccc;
  transform: translate(0);
}

.error {
  color: #e77224;
}

/*# sourceMappingURL=index.7d6cc80e.css.map */
