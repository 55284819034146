body {
    font-family: 'Roboto', sans-serif;
}

header>h1 {
    font-family: 'Niconne', cursive;
    font-weight: normal;
    font-size: 4rem;
    text-align: center;
    color: rgb(91, 150, 24);
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.form-container input[type="number"] {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    font-size: 1rem;
    background-color: #f1f1f1;
    padding: 6px;
    width: 100px;
    border-radius: 3px;
}

.form-container input:focus-visible {
    outline: 0;
    border-bottom-color:  rgb(91, 150, 24);
}

.form-container button {
    border: none;
    background-color: rgb(91, 150, 24);
    color: #fff;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
}

.form-container button:hover {
    background-color: rgb(115, 172, 51);
}

.form-container button:active {
    transform: translate(1,1);
}

.form-container button:disabled {
    background-color: #ccc;
    transform: translate(0,0);
    cursor: default;
}

.error {
    color: rgb(231, 114, 36);
}